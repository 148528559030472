import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import KitchenFilter from "components/filters/KitchenFilter";
import SearchFilter from "components/filters/SearchFilter";
import StatusFilter from "components/filters/StatusFilter";
import TimeFilter from "components/filters/TimeFilter";
import DateFilter from "components/filters/DateFilter";
import DateRangeFilter from "components/filters/DateRangeFilter";
import MultiVBFilter from "components/filters/MultiVBFilter";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import { ServiceId } from "constants/serviceType";
import BrandFilter from "components/filters/BrandFilter";
import OrdersNotificationContext from "store/orders-notification-context";

const OrderFilters = (props) => {
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const filtersData = props?.filtersData;
  const type = props?.type;
  const [selectedKitchenId, setSelectedKitchenId] = useState(filtersData?.selectedKitchenId);
  const [selectedKitchen, setSelectedKitchen] = useState(filtersData?.selectedKitchen);
  const [searchTerm, setsearchTerm] = useState(filtersData?.searchTerm);
  const [orderStatus, setOrderStatus] = useState(filtersData?.orderStatus);
  const [dateFrom, setDateFrom] = useState(filtersData?.dateFrom);
  const [dateTo, setDateTo] = useState(filtersData?.dateTo);
  const [selectedBrandIds, setSelectedBrandIds] = useState(filtersData?.selectedBrandIds);
  const [marketPlaceStatus, setMarketPlaceStatus] = useState(filtersData?.marketPlaceStatus);
  const [selectedServiceIds, setSelectedServiceIds] = useState(
    typeof filtersData?.marketPlaceStatus === "string"
      ? filtersData?.marketPlaceStatus.split(",").map((item) => Number(item))
      : []
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    filtersData?.selectedTimeRange
  );
  const [selectedVB, setSelectedVB] = useState(filtersData?.selectedVB);
  const [selectedBrandId, setSelectedBrandId] = useState(filtersData?.selectedBrandId);

  const onKitchenSelect = (data) => {
    if (data) {
      setSelectedKitchenId(data.value);
      setSelectedKitchen(data.label);
    }
  };

  const onSearch = (term) => {
    setsearchTerm(term);
  };

  const onStatuses = (data) => {
    if (data?.orderStatus && data?.orderStatus?.length) {
      setOrderStatus(data?.orderStatus?.join(","));
    } else {
      setOrderStatus(null);
    }
    if (data?.marketplaceStatus && data?.marketplaceStatus?.length) {
      setMarketPlaceStatus(data?.marketplaceStatus?.join(","));
    } else {
      setMarketPlaceStatus(null);
    }
  };

  const onTime = (data) => {
    setDateTo(data?.dateTo ? new Date(data?.dateTo) : null);
    setDateFrom(data?.dateFrom ? new Date(data?.dateFrom) : null);
    setSelectedTimeRange(data?.selectedValue);
  };

  const onDates = (data) => {
    setDateTo(data?.dateTo ? new Date(data?.dateTo) : null);
    setDateFrom(data?.dateFrom ? new Date(data?.dateFrom) : null);
    setSelectedTimeRange(data?.selectedValue);
  };

  const onMultiBrandSelect = (data, brandsCount) => {
    if (data && data?.length > 0 && brandsCount !== data?.length) {
      const selectedBrandIds = data
        .map((ele) => ele.value)
        .filter((value) => value !== 0);
      setSelectedBrandIds(selectedBrandIds?.join(","));
      setSelectedVB(data);
    } else {
      setSelectedBrandIds(null);
      setSelectedVB(null);
    }
  };

  const onBrandSelect = (data) => {
    if (data) {
      setSelectedBrandId(data.value);
    }
  };

  const onSubmit = () => {
    if (type === "reports") {
      const filtersObj = {
        selectedKitchenId: selectedKitchenId,
        selectedKitchen: selectedKitchen,
        searchTerm: searchTerm,
        orderStatus: orderStatus,
        marketPlaceStatus: marketPlaceStatus,
        dateFrom: dateFrom,
        dateTo: dateTo,
        selectedBrandIds: selectedBrandIds,
        selectedBrandId: selectedBrandId,
        selectedTimeRange: selectedTimeRange,
        selectedVB: selectedVB,
      };
      console.log("inside onSubmit::", filtersObj);
      OrdersNotificationCtx.saveReportsFilterData(filtersObj);
    } else {
      const filtersObj = {
        selectedKitchenId: selectedKitchenId,
        selectedKitchen: selectedKitchen,
        searchTerm: searchTerm,
        orderStatus: orderStatus,
        marketPlaceStatus: marketPlaceStatus,
        dateFrom: dateFrom,
        dateTo: dateTo,
        selectedBrandIds: selectedBrandIds,
        selectedBrandId: selectedBrandId,
        selectedTimeRange: selectedTimeRange,
        selectedVB: selectedVB,
      };
      OrdersNotificationCtx.saveFiltersData(filtersObj);
    }
    props.onHide();
  };

  return (
    <>
      <div className="row no-gutters basic__info__content order__filters">
        <div className="col-md-12 mb-3 pb-3 mt-3 pt-3">
          <label>Restaurant</label>
          <div className="filter__height">
            <KitchenFilter
              value={selectedKitchenId}
              onSelect={onKitchenSelect}
            />
          </div>
        </div>

        {type === "orders" && (
          <div className="col-md-12 mb-3 pb-3">
            <label>Search by Customer Details or Order ID</label>
            <SearchFilter
              value={searchTerm}
              onSelect={onSearch}
              placeHolder={
                "Enter Order ID, Customer Name, Phone Number, Address, ID..."
              }
            />
          </div>
        )}

        {type === "reports" && (
          <div className="col-md-12 mb-3 pb-3">
            <label>Virtual Brand</label>
            <BrandFilter
              kitchenId={selectedKitchenId}
              value={selectedBrandId}
              onSelect={onBrandSelect}
            />
          </div>
        )}

        <div className="col-md-12 mb-3 pb-3">
          <label>Channel</label>
          <div className="channel__wrapper">
            <StatusFilter
              orderValue={orderStatus}
              marketplaceValue={marketPlaceStatus}
              onSelect={onStatuses}
              selectedServiceIds={selectedServiceIds}
              isViewOrderStatus={type === "reports" ? false : true}
              isApply={false}
            />
          </div>
        </div>

        <div className="col-md-12 mb-3 pb-3">
          <label>Date Range</label>
          <div className="row">
            <div className="col-md-6">
              <div className="user__custom__select filter__height">
                <TimeFilter
                  value={{
                    dateFromValue: dateFrom,
                    dateToValue: dateTo,
                  }}
                  selectedTimeRange={selectedTimeRange}
                  onSelect={onTime}
                />
              </div>
            </div>

            <div className="col-md-6">
              {/* <DateFilter
                  dateToValue={dateTo}
                  dateFromValue={dateFrom}
                  onSelect={onDates}
                /> */}
              <DateRangeFilter
                dateToValue={dateTo}
                dateFromValue={dateFrom}
                onSelect={onDates}
              />
            </div>
          </div>
        </div>

        {type === "orders" && (
          <div className="col-md-12">
            <label>Virtual Brand</label>
            <MultiVBFilter
              kitchenId={selectedKitchenId}
              value={selectedVB}
              onSelect={onMultiBrandSelect}
            />
          </div>
        )}
      </div>
      <Modal.Footer>
        {props.children}
        <button
          type="submit"
          className="red-btn next-btn btn"
          onClick={onSubmit}
          //disabled={!isValid}
        >
          Apply
          <span className="icons-right">
            <img src={rightArrowIcon} alt="arrow icon" />
          </span>
        </button>
      </Modal.Footer>
    </>
  );
};

export default OrderFilters;
