import React, { useEffect, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import "./ModifierListing.scss";
import searchIcon from "assets/images/icon-search.svg";
import plusIcon from "assets/images/add-icon-white.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import { Global } from "constants/global";
import { getModifierGroups } from "services/api/modifier";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import ListingRow from "./ListingRow";
import backArrowIcon from "assets/images/back-arrow.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const modParams = new URLSearchParams();

const ModifierListing = () => {
  const pageSize = Global.pageSize;

  const history = useHistory();
  const location = useLocation();

  const page = new URLSearchParams(location?.search).get("page");
  const search = new URLSearchParams(location?.search).get("search");

  const [pageNum, setpageNum] = useState(page || 1);
  const [searchTerm, setsearchTerm] = useState(search || null);
  const [searchValue, setsearchValue] = useState(search || null);

  const [allModifiers, setallModifiers] = useState(null);
  const [totalRecords, settotalRecords] = useState(0);

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (location) {
      if (location?.search) {
        let page = new URLSearchParams(location?.search).get("page");
        let search = new URLSearchParams(location?.search).get("search");
        if (page) {
          setpageNum(+page);
          modParams.set("page", page);
        } else {
          setpageNum(1);
          modParams.delete("page");
        }
        if (search) {
          setsearchTerm(search);
          modParams.set("search", search);
        } else {
          setsearchTerm(null);
          modParams.delete("search");
        }
      } else {
        console.log("inside else::",{location});
        setpageNum(1);
        modParams.delete("page");
        setsearchTerm(null);
        modParams.delete("search");
      }
    }
  }, [location]);

  useEffect(() => {
    getListing();
  }, [pageNum, searchTerm]);

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchTerm,
    };
    setisLoading(true);
    await getModifierGroups(masks)
      .then((res) => {
        let data = res.data;
        setallModifiers(data.menuItemMods);
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setallModifiers(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    if (page) {
      modParams.set("page", page);
    } else {
      modParams.delete("page");
    }
    history.push({ search: modParams.toString() });
  };

  const onSearch = (e) => {
    let search = e.target.value;
    setsearchValue(search);
    if (e.key === "Enter" || search === "") {
      if (search) {
        modParams.set("search", search);
      } else {
        modParams.delete("search");
      }
      //setpageNum(1);
      modParams.delete("page");
      history.push({ search: modParams.toString() });
    }
  };

  const onSearchValue = () => {
      if (searchValue) {
        modParams.set("search", searchValue);
      } else {
        modParams.delete("search");
      }
      //setpageNum(1);
      modParams.delete("page");
      history.push({ search: modParams.toString() });
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header">
            <div className="row">
              <div className="col-12 mb-3 pb-3 d-none d-md-block">
                <div className="goBack">
                  <Link
                    to="/dashboard"
                    className="back-btn-red"
                    title="Go to Dashboard"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Go to Dashboard
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-0 mb-md-3 mb-lg-0">
                <h1>Manage Modifiers</h1>
              </div>
              <div className="col-12 col-lg-6">
                <div
                  className="form flex-column flex-md-row"
                  id="manage__modifier__search__form"
                >
                  <div className="search__modifiers">
                    <div className="search_modifier_group">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="text"
                            className="custom-file form-control"
                            placeholder="Search modifier groups"
                            defaultValue={searchTerm}
                            onKeyUp={onSearch}
                          />
                          <button type="button" className="search-btn" onClick={() => onSearchValue()}>
                            <span className="icons">
                              <img src={searchIcon} alt="plus icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/modifiers/add" className="red-btn btn">
                    <span className="icons-left icons">
                      <img src={plusIcon} alt="add icon" />
                    </span>
                    Add New Modifier
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {allModifiers && totalRecords === 0 ? (
            <div className="managermodifier__noListing">
              <div className="managermodifier__noListing__innerwrapper">
                <div className="content">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={emptyIocn}
                      alt="no list icon"
                      width="185"
                    />
                  </div>
                  <div className="content-desc">
                    <h3>
                      {searchTerm && searchTerm !== ""
                        ? "No modifier(s) found"
                        : "No modifiers are added"}
                    </h3>
                    <p>
                      Please click on “Add New Modifier” to create new
                      modifiers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="managermodifier__listinPage">
              <div className="managermodifier__listinPage__wrapper">
                <table className="table modifierListing__table">
                  <thead>
                    <tr role="row" className="mat-header-row">
                      <th scope="col" className="mat-header-cell column-1">
                        Name
                      </th>
                      <th scope="col" className="mat-header-cell column-2">
                        Modifier Type
                      </th>
                     {/*  <th scope="col" className="mat-header-cell column-3">
                        Creation Date
                      </th> */}
                      <th scope="col" className="mat-header-cell column-4">
                        Last Modified
                      </th>
                      <th scope="col" className="mat-header-cell column-5">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td>
                          <LoaderBlock />
                        </td>
                      </tr>
                    ) : (
                      allModifiers?.map((modifier, idx) => (
                        <ListingRow
                          key={idx}
                          modifierGroup={modifier}
                          refreshList={getListing}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-12">
                    <Paginator
                      totalCount={totalRecords}
                      currentPage={pageNum}
                      pageSize={pageSize}
                      pageClick={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModifierListing;
