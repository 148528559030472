import React, { useState } from "react";
import { useEffect } from "react";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";

const TimeFilter = (props) => {
  const dateTo = props.dateToValue;
  const dateFrom = props.dateFromValue;
  const sendInfo = props.onSelect;

  const msInADay = 86400000;
  const selectedDropdownLabel = props?.selectedTimeRange;
  const [selectedValue, setselectedValue] = useState(props?.selectedTimeRange);

  useEffect(() => {
  setselectedValue(selectedDropdownLabel);
  }, [selectedDropdownLabel]);

  const options = [
    { label: "All Time", value: 0 },
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last Week", value: 3 },
    { label: "Last 30 Days", value: 4 },
    { label: "Custom", value: 5 },
  ];

  const onDateMatch = (timeValue) => {
    let dateFrom = new Date();
    let dateTo = new Date();
    switch (timeValue) {
      case 0:
        dateTo = null;
        dateFrom = null;
        break;
      case 1:
        dateFrom = new Date();
        break;
      case 2:
        dateTo = new Date(Date.now() - msInADay * 1);
        dateFrom = new Date(Date.now() - msInADay * 1);
        break;
      case 3:
        dateFrom = new Date(Date.now() - msInADay * 7);
        break;
      case 4:
        dateFrom = new Date(Date.now() - msInADay * 30);
        break;
      case 5:
        dateFrom = null;
        dateTo = null;
        break;
      default:
        break;
    }
    sendInfo({
      dateTo: dateTo?.setHours(23, 59, 59, 59),
      dateFrom: dateFrom?.setHours(0, 0, 0, 0),
      selectedValue: timeValue
    });
  };

  const onSelection = (data) => {
    onDateMatch(data);
    setselectedValue(data);
  };

  return (
    <>
      <SelectDropdown
        options={options}
        selectedValue={(val) => onSelection(val.value)}
        value={selectedValue}
      />
    </>
  );
};

export default TimeFilter;
