import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./ManageCustomerListing.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import exportIcon from "assets/images/export-icon.svg";
import filterIcon from "assets/images/Icon_Filter_ckc.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import LoaderBlock from "shared/loader/LoaderBlock";
import Paginator from "shared/paginator/Paginator";
import KitchenFilter from "components/filters/KitchenFilter";
import SearchFilter from "components/filters/SearchFilter";
import StatusFilter from "components/filters/StatusFilter";
import TimeFilter from "components/filters/TimeFilter";
import DateFilter from "components/filters/DateFilter";
import MultiVBFilter from "components/filters/MultiVBFilter";
import ListingRow from "./ListingRow";
import { Global } from "constants/global";
import { getAllCustomers } from "services/api/customer";
import dateFormat from "dateformat";
import { numberFormat } from "constants/numberFormat";
import downloadCsv from 'download-csv';
import { ServiceId } from "constants/serviceType";
import OrdersNotificationContext from "store/orders-notification-context";

const ManageCustomerListing = () => {

    const OrdersNotificationCtx = useContext(OrdersNotificationContext);
    const useFilters = OrdersNotificationCtx?.customersFilterData;

    const [pageNum, setpageNum] = useState(0);
    const [selectedKitchenId, setSelectedKitchenId] = useState(useFilters?.kitchenId);
    const [selectedKitchen, setSelectedKitchen] = useState(null);
    const [searchTerm, setsearchTerm] = useState(useFilters?.name);
    const [orderStatus, setOrderStatus] = useState(useFilters?.orderStatus);
    const [selectedBrandIds, setSelectedBrandIds] = useState(useFilters?.brandIds);
    const msInADay = 86400000;
    const [dateFrom, setDateFrom] = useState(useFilters?.dateFrom);
    const [dateTo, setDateTo] = useState(useFilters?.dateTo);
    const [allCustomers, setAllCustomers] = useState(null);
    const [totalRecords, settotalRecords] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [filterToggle, setFilterToggle] = useState(false);
    const history = useHistory();
    const { search } = useLocation();
    const orderParams = new URLSearchParams();
    const pageSize = Global.pageSize;
    const [marketPlaceStatus, setMarketPlaceStatus] = useState(useFilters?.marketPlaceStatus);
    const [selectedServiceIds, setSelectedServiceIds] = useState(
      typeof useFilters?.marketPlaceStatus === "string"
        ? useFilters?.marketPlaceStatus.split(",").map((item) => Number(item))
        : []
    );
    const [selectedTimeRange, setSelectedTimeRange] = useState(useFilters?.selectedTimeRange);

    useEffect(() => {
        if (search) {
            let page = new URLSearchParams(search).get("page");
            let searchVal = new URLSearchParams(search).get("search");
            if (page) {
                setpageNum(+page);
                orderParams.set("page", page);
            } else {
                setpageNum(1);
                orderParams.delete("page");
            }
            if (searchVal) {
                setsearchTerm(searchVal);
                orderParams.set("search", searchVal);
            }else if(searchTerm){
              orderParams.set("search", searchTerm);
            }
            else {
                setsearchTerm(null);
                orderParams.delete("search");
            }
        } else {
            setpageNum(1);
            orderParams.delete("page");
            orderParams.delete("search");
        }
    }, [search]);
    
    
    useEffect(() => {
      pageNum !== 0 && getListing();
    }, [
        pageNum,
        searchTerm,
        selectedKitchenId,
        selectedBrandIds,
        orderStatus,
        marketPlaceStatus,
        dateTo,
        dateFrom,
    ]);

    const getListing = async () => {
        let masks = {
            pageNum: pageNum,
            pageSize: pageSize,
            name: searchTerm,
            kitchenId: selectedKitchenId,
            orderStatus: orderStatus,
            marketPlaceStatus: marketPlaceStatus,
            dateTo: dateTo,
            dateFrom: dateFrom,
            brandIds: selectedBrandIds
        };
        setisLoading(true);
        OrdersNotificationCtx.saveCustomersFilterData({...masks, selectedTimeRange});
        await getAllCustomers(masks)
            .then((res) => {
                let data = res.data;
                setAllCustomers(data?.customerListForKitchenData);
                settotalRecords(data?.totalRecordCount);
                setisLoading(false);
            })
            .catch(() => {
                setAllCustomers(null);
                settotalRecords(0);
                setisLoading(false);
            });
    };

    console.log({allCustomers})

    const exportCustomersCSV = () => {
      const customersData = allCustomers;
      const datas = [];
      customersData.map((item) => {
        const obj = {
          CreationDate: item.creationDate ? `"${dateFormat(new Date(item?.creationDate + "Z"), "mmm dd, yyyy")}"` : "NA",
          Customer: item?.customer || "NA",
          AddressLine1: item.addressLine1 ? `"${item.addressLine1}"` : "NA",
          AddressLine2: item.addressLine2 ? `"${item.addressLine2}"` : "NA",
          City: item?.city || "NA",
          State: item?.state || "NA",
          Zip: item?.zip || "NA",
          Phone: item?.phone || "NA",
          Email: item?.email ? `"${item.email}"` : "NA",
          TotalOrders: item?.totalOrders || 0,
          TotalSales: numberFormat(item?.totalSales || 0),
        };
        datas.push(obj);
      });
      const columns = {
        CreationDate: "Creation Date",
        Customer: "Customer",
        AddressLine1: "Address Line1",
        AddressLine2: "Address Line2",
        City: "City",
        State: "State",
        Zip: "Zip",
        Phone: "Phone",
        Email: "Email",
        TotalOrders: "Total Orders",
        TotalSales: "Total Sales",
      };
      const fileName = "CustomersCSV" + dateFormat(new Date(), "yyyy-mm-dd, hh:MM:ss");
      downloadCsv(datas, columns, `${fileName}.csv`);
    };

    const onKitchenSelect = (data) => {
        if (data) {
            setSelectedKitchenId(data.value);
            setSelectedKitchen(data.label);
            setpageNum(1);
        }
    };

    const onSearch = (term) => {
        if (term) {
            orderParams.set("search", term);
        } else {
            orderParams.delete("search");
        }
        setpageNum(1);
        orderParams.delete("page");
        history.push({ search: orderParams.toString() });
    };

    const onStatuses = (data) => {
        if (data?.orderStatus && data?.orderStatus?.length) {
            setOrderStatus(data?.orderStatus?.join(","));
        } else {
            setOrderStatus(null);
        }
        if (data?.marketplaceStatus && data?.marketplaceStatus?.length) {
            setMarketPlaceStatus(data?.marketplaceStatus?.join(","));
        } else {
            setMarketPlaceStatus(null);
        }
        setpageNum(1);
    };

    const onTime = (data) => {
        setDateTo(data?.dateTo);
        setDateFrom(data?.dateFrom);
        setSelectedTimeRange(data?.selectedValue);
    };

    const onDates = (data) => {
        setDateTo(data?.dateTo);
        setDateFrom(data?.dateFrom);
        setSelectedTimeRange(data?.selectedValue);
        setpageNum(1);
    };

    const onBrandSelect = (data, brandsCount) => {
        if (data && data?.length > 0 && brandsCount !== data?.length) {
          const selectedBrandIds = data.map(ele => {
            return ele.value;
          });
          setSelectedBrandIds(selectedBrandIds?.join(","));
        }
        else {
          setSelectedBrandIds(null);
        }
        setpageNum(1);
      };

    const onFilterToggle = () => setFilterToggle(!filterToggle);

    const onPageChange = (page) => {
        if (page) {
            orderParams.set("page", page);
        } else {
            orderParams.delete("page");
        }
        history.push({ search: orderParams.toString() });
    };

    return (
      <>
        <Subheader>
          <div className="goBack mb-3">
            <Link
              to="/dashboard"
              className="back-btn-red"
              title="Go to Dashboard"
            >
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Go to Dashboard
            </Link>
          </div>
        </Subheader>
        <div className="reports__wrapper">
          <div className="orders__page__wrapper">
            <div className="managermodifier__wrapper basic__info__content">
              <div className="container">
                <div className="managermodifier__header">
                  <div className="row justify-content-between">
                    <div className="col-12 mb-3 pb-3 d-none d-md-block">
                      <div className="goBack">
                        <Link
                          to="/dashboard"
                          className="back-btn-red"
                          title="Go to Dashboard"
                        >
                          <span className="icons">
                            <img src={backArrowIcon} alt="back icon" />
                          </span>
                          Go to Dashboard
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 mb-4 d-flex justify-content-between">
                      <h1>Manage Customers</h1>
                      <div className="export__filters__wrapper">
                        <div className="export__wrapper">
                          <button
                            className="export-btn btn"
                            onClick={exportCustomersCSV}
                          >
                            <span>
                              <img
                                src={exportIcon}
                                alt="export icon"
                                className="img-fluid icons"
                              />
                            </span>
                            <span>Export CSV</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* begin:: filters for web screens */}
                    <div className="col-12 d-none d-xl-block">
                      <div className="d-flex flex-row form">
                        <div>
                          <label className="filters__label">Restaurant</label>
                          <div className="customers__kitchen__filter userselect mr-md-3">
                            <div className="user__custom__select">
                              <KitchenFilter
                                value={selectedKitchenId}
                                onSelect={onKitchenSelect}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="filters__label">
                            Search Customer
                          </label>
                          <div className="customers__search search__modifiers mr-md-3">
                            <SearchFilter
                              value={searchTerm}
                              onSelect={onSearch}
                              placeHolder={"Search Customer"}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="filters__label">Channel</label>
                          <div className="customers__channel__filter reports__status__multiselect__wrapper mr-md-3">
                            <StatusFilter
                              orderValue={orderStatus}
                              marketplaceValue={marketPlaceStatus}
                              onSelect={onStatuses}
                              selectedServiceIds={selectedServiceIds}
                              isViewOrderStatus={true}
                              isApply={true}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="filters__label">Date Range</label>
                          <div className="userselect mr-md-3">
                            <div className="user__custom__select">
                              <TimeFilter
                                value={{
                                  dateFromValue: dateFrom,
                                  dateToValue: dateTo,
                                }}
                                selectedTimeRange={selectedTimeRange}
                                onSelect={onTime}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="filters__label"></label>
                          <div className="customers__date__filter date__filter__wrapper mr-md-3">
                            <DateFilter
                              dateToValue={dateTo}
                              dateFromValue={dateFrom}
                              onSelect={onDates}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-none d-xl-block mt-3">
                      <label className="filters__label">Virtual Brand</label>
                      <MultiVBFilter
                        kitchenId={selectedKitchenId}
                        /*   value={selectedBrandId} */
                        onSelect={onBrandSelect}
                      />
                    </div>
                    {/* end:: filters for web screens */}
                    {/* begin:: filters for mobile screens */}
                    <div className="order__filters__mobile__screens">
                      <div className="col-12 d-xl-none">
                        <div className="d-flex flex-column w-100 form">
                          <div className="userselect w-100 mb-4">
                            <KitchenFilter
                              value={selectedKitchenId}
                              onSelect={onKitchenSelect}
                            />
                          </div>
                          <div className="filters__wrapper__mobile mb-3">
                            <div className="filters__toggle__mobile">
                              <button
                                type="button"
                                className={
                                  "filters " +
                                  (searchTerm ||
                                  orderStatus ||
                                  marketPlaceStatus ||
                                  dateTo ||
                                  dateFrom
                                    ? "filter__applied"
                                    : "")
                                }
                                onClick={onFilterToggle}
                              >
                                <span>
                                  <img
                                    src={filterIcon}
                                    alt="export icon"
                                    className="img-fluid"
                                  />
                                </span>
                              </button>
                            </div>
                            <div className="export__wrapper">
                              <button
                                className="export-btn btn"
                                onClick={exportCustomersCSV}
                              >
                                <span>
                                  <img
                                    src={exportIcon}
                                    alt="export icon"
                                    className="img-fluid icons"
                                  />
                                </span>
                                <span>Export CSV</span>
                              </button>
                            </div>
                          </div>
                          {filterToggle ? (
                            <div className="filters__hidden__options">
                              <div className="row">
                                <div className="col-12 mb-3">
                                  <div className="d-flex search_n_orderFilters">
                                    <div className="search__modifiers">
                                      <SearchFilter
                                        value={searchTerm}
                                        onSelect={onSearch}
                                        placeHolder={"Search Customer"}
                                      />
                                    </div>
                                    <div className="order__status__multiselect__wrapper">
                                      <StatusFilter
                                        orderValue={orderStatus}
                                        marketplaceValue={marketPlaceStatus}
                                        onSelect={onStatuses}
                                        selectedServiceIds={selectedServiceIds}
                                        isViewOrderStatus={true}
                                        isApply={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-xl-5">
                                  <div className="userselect mb-3">
                                    <div className="user__custom__select">
                                      <TimeFilter
                                        value={{
                                          dateFromValue: dateFrom,
                                          dateToValue: dateTo,
                                        }}
                                        selectedTimeRange={selectedTimeRange}
                                        onSelect={onTime}
                                      />
                                    </div>
                                  </div>
                                  <div className="date__filter__wrapper">
                                    <DateFilter
                                      dateToValue={dateTo}
                                      dateFromValue={dateFrom}
                                      onSelect={onDates}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {allCustomers && totalRecords === 0 && !isLoading ? (
                  <div className="managermodifier__noListing">
                    <div className="managermodifier__noListing__innerwrapper">
                      <div className="content">
                        <div className="img-box">
                          <img
                            className="img-fluid"
                            src={emptyIocn}
                            alt="no list icon"
                            width="185"
                          />
                        </div>
                        <div className="content-desc">
                          <h3>No Customers Found</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="managermodifier__listinPage customers__listinPage" style={{minHeight: "50vh"}}>
                    <div className="managermodifier__listinPage__wrapper">
                      <table className="table modifierListing__table">
                        <thead>
                          <tr role="row" className="mat-header-row">
                            <th
                              scope="col"
                              className="mat-header-cell column_1"
                            >
                              Customer
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column_2"
                            >
                              Phone
                            </th>
                           {/*  <th
                              scope="col"
                              className="mat-header-cell column_3"
                            >
                              Email
                            </th> */}
                            <th
                              scope="col"
                              className="mat-header-cell column_4"
                            >
                              # Orders
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column_5"
                            >
                              Total Sales
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column_6"
                            >
                              Location
                            </th>
                            {/*  <th scope="col" className="mat-header-cell column_7">
                                                        Action
                                                    </th>    */}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td>
                                <LoaderBlock />
                              </td>
                            </tr>
                          ) : (
                            allCustomers &&
                            allCustomers?.map((customer, idx) => (
                              <ListingRow customer={customer} key={idx} />
                            ))
                          )}
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-12">
                          <Paginator
                            totalCount={totalRecords}
                            currentPage={pageNum}
                            pageSize={pageSize}
                            pageClick={onPageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default ManageCustomerListing;