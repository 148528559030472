import { createContext, useState } from "react";
import { ServiceId } from "constants/serviceType";

const OrdersNotificationContext = createContext({
  orderNotifications: null,
  saveOrderNotifications: (orderNotification) => {},
  deleteOrderNotifications: (orderNotification) => {},
  resetAllVariables: () => {},
  updatedOrderDetails: null,
  saveUpdatedOrderDetails: (event) => {},
  isFullScreen: false,
  saveFullScreenState: (status) => {},
  filtersData: null,
  saveFiltersData: (data) => {},
  reportsFiltersData: null,
  saveReportsFilterData: () => {},
  customersFilterData: null,
  saveCustomersFilterData: () => {},
  drawerState: null,
  saveDrawerState: (status) => {},
});

export function OrdersNotificationContextProvider(props) {
  const [orderNotifications, setOrderNotifications] = useState([]);
  const [updatedOrderDetails, setUpdatedOrderDetails] = useState([]);
  const [isFullScreen, setFullScreen] = useState(localStorage?.getItem('isFullScreen'));
  const msInADay = 86400000;
  const defaultMarketPlaceIds = [
    ServiceId.DOORDASH,
    ServiceId.GRUBHUB,
    ServiceId.UBEREATS,
    ServiceId.POSTMATES,
    ServiceId.FOODDUDES,
    ServiceId.MENUFY
  ];
  const [filtersData, setFiltersData] = useState({
    dateFrom: new Date(Date.now() - msInADay * 7),
    dateTo: new Date(),
    selectedTimeRange: 3, 
    marketPlaceStatus: defaultMarketPlaceIds,
  });
  const [reportsFiltersData, setReportsFiltersData] = useState({
    dateFrom: new Date(Date.now() - msInADay * 7),
    dateTo: new Date(),
    marketPlaceStatus: defaultMarketPlaceIds,
  });
  const [customersFilterData, setCustomersFiltersData] = useState({
    dateFrom: new Date(Date.now() - msInADay * 7),
    dateTo: new Date(),
    selectedTimeRange: 3,
    marketPlaceStatus: defaultMarketPlaceIds,
  });
  const [drawerState, setDrawerState] = useState(
    JSON.parse(localStorage?.getItem("drawerStatus")) || {
      isOpen: false,
      orderId: null,
    }
  );

  const orderNotificationsHandler = (orderNotification) => {
    setOrderNotifications((currentState) =>
      Array.from(new Set([...currentState, ...orderNotification]))
    );
  };

  const updatedOrderDetailsHandler = (event) => {
    setUpdatedOrderDetails(event);
  };

  const fullScreenStateHandler = (status) => {
    if (status === false) {
      localStorage?.removeItem("isFullScreen");
    }
    else {
      localStorage?.setItem("isFullScreen", status);
    }
     setFullScreen(status);
  };

  const filtersDataHandler = (data) => {
    setFiltersData(data);
  }

  const customersFilterHandler = (data) => {
    setCustomersFiltersData(data);
  }

  const reportsFiltersDataHandler = (data) => {
    setReportsFiltersData(data);
  }

  const drawerStateHandler = (data) => {
    if (data?.isOpen === false) {
      localStorage?.removeItem("drawerStatus");
    } else {
     localStorage?.setItem("drawerStatus", JSON.stringify(data));
    }
    setDrawerState(data);
  }
  
  const deleteOrderNotificationsHandler = (orderNotification) => {
    setOrderNotifications([...orderNotification]);
  };
  const deleteAllVariables = () => {
    setOrderNotifications(null);
  };

  // actual context below from here
  const context = {
    orderNotifications: orderNotifications,
    saveOrderNotifications: orderNotificationsHandler,
    deleteOrderNotifications: deleteOrderNotificationsHandler,
    resetAllVariables: deleteAllVariables,
    updatedOrderDetails: updatedOrderDetails,
    saveUpdatedOrderDetails: updatedOrderDetailsHandler,
    isFullScreen: isFullScreen,
    saveFullScreenState: fullScreenStateHandler,
    filtersData: filtersData,
    saveFiltersData: filtersDataHandler,
    reportsFiltersData: reportsFiltersData,
    customersFilterData: customersFilterData,
    saveCustomersFilterData: customersFilterHandler,
    saveReportsFilterData: reportsFiltersDataHandler,
    drawerState: drawerState,
    saveDrawerState: drawerStateHandler,
  };

  return (
    <OrdersNotificationContext.Provider value={context}>
      {props.children}
    </OrdersNotificationContext.Provider>
  );
}

export default OrdersNotificationContext;
